.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    background-color: #FFFFFF;
    padding-top: 5vh;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-bottom: 3vh;

    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    max-height: 100%;
    position: relative; /* Afegit per alineació de close-button */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    color: #D1D1D1;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
  }
  
  .close-button:hover {
    color: #1D1D1B;
  }
  
  .link-row {
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .icon-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 25px;
    align-items: center;
  }

  .icona-instagram,
  .icon-facebook,
  .icon-twitter,
  .icon-tiktok,
  .icon-link {
    color: #ccc; /* Color grisos inicial */
    font-size: 50px;
    width: 50px;
    height: 50px;
    padding: 5px;
    transition: color 0.3s; /* Transició de color en l'hover */
  }

.icona-instagram:hover {
  color: #fff;
  background-image: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.icon-facebook:hover {
    color: #3b5998;
}

.icon-twitter:hover {
    color: black;
}

.icon-tiktok:hover {
    color: black;
}

.icon-link:hover{
  color: gray;
}

.colored-svg {
  fill: #ccc;
}

.colored-svg:hover {
  fill: black;
}

.separador {
    display: block;
    width: 95%;
    height: 1px;
    border: 0;
    border-top: 1px solid #D91E01;
    padding: 0;
    margin: 1em 0;
}
