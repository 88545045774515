/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.content-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  border-bottom: 20px solid #FFFFFF;
}

.content-container .image-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.content-container .blur-image-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.content-container .text-overlay {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #1D1D1D;
  font-size: 24px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  max-width: 100%;
  padding-left: 50%;
  padding-right: 50%;
}
