.container {
    text-align: center; 
    background-color: #FFF;
    color: #000;
    padding: 20px;
    font-size: 2vw;
    font-family: 'Rubik', sans-serif;
  }

  .container h2 {
    font-size: 4vw;
}