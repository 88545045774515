/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.navbar {
    background:#ffffff; /* Color de fons de la navigation bar */
    height: 80px; /* Alçada de la navigation bar */
    display: flex; /* Posició de la navigation bar */
    justify-content: center; /* Justificació del contingut */
    align-items: center; /* Justificació dels items de la navigation bar */
    font-size: 20px; /* Tamany del text de la navigation bar */
    margin : 0; /* Elimina el default margin */
    max-width: 1440px; /* Estableix el màxim d'amplada */
    width: 100%;
    position:relative;
    z-index: 2;
    
  }
  
  .navbar-logo {
    display: flex; /* Posició del logo dins la navigation bar */
    align-items: center; /* Alineació del logo dins la navigation bar */
    margin-left: 20px; /* Marge del logo */
    cursor: pointer; /* Quan el cursor passa per sobre es converteix en una mà, per indicar que és clickable */
  }
  
  .navbar-logo img{
    margin-left: 10px;
    width: 193px; /* Estableix l'amplada de la imatge */
    height: 80px; /* Estableix l'alçada de la imatge */
  }

  .fa-firstdraft {
    margin-left: 0.5rem; /* No tocar */
    font-size: 1.6rem; /* No tocar */
  }
  
  .nav-menu {
    display: flex; /* Posició del menú dins la navigation bar */
    justify-content: right; /* Alineació dels elements del menú al centre */
    list-style: none; /* Sense estil */
    text-align: center; /* Text alineat al centre */
    width: 100%; /* Canvi del tamany al 100% */
    padding: 0; /* No hi ha padding */
    margin-right: 50px; /* Elimina el dafault margin */
    font-family: 'Roboto', sans-serif; /* Use Roboto font */    
    overflow: hidden;
    font-size: 18px;
  }
  
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center; /* Add this line to center the text vertically */
    border-right: 1px solid #F1F1F1;
  }
  .nav-item:last-child{
    border-right: none;
  }
  
  .nav-links {
    color: #1D1D1B; /* Color dels text que es veuen, no inclou el dropdown */
    text-decoration: none; /* No té decoració el text */
    padding-left: 1.2rem;
    padding-right:1.2rem;
    padding-top:30px;
    padding-bottom:30px;
  }
  
  .nav-links:hover {
    background-color: #D91E01; /* Background color on hover, no inclou el dropdown */
    color :#fff; /* Color del text on hover, no inclou el dropdown */
    transition: all 0.2s ease-out; /* Temps que tarda en fer la transició entre els colors */
  }
  
  .fa-bars {
    color: #fff; /* No tocar */
  }
  
  .nav-links-mobile {
    display: none; /* No tocar */
  }
  
  .menu-icon {
    display: none; /* No tocar */
  }

  /* Provisional */
  h2{
    text-align: center;
  }

  @media (max-width: 1109px) {
    .navbar {
      display: none;
    }
  }
  