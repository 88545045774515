.card {
    position: relative;
    margin: auto auto;
    width: 500px;
    height: 500px;
    overflow: hidden;
    border-radius: 20px;
    opacity: 70%;
    margin-bottom: 10px;
  }
  
  .card:hover {
    transition: all .25s ease-in;
    opacity: 100%;
  }

  .ticket-img img {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    }
  
  .card_title {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;;
  }
  
  .card_content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background: white;
    border-top-left-radius: 20px;
    transform: translateY(150px);
    transition: transform .25s;
    font-family: 'Rubik', sans-serif;
  }
  
  .card_content::before {
    content: '';
    position: absolute;
    top: -47px;
    right: -45px;
    width: 100px;
    height: 100px;
    transform: rotate(-175deg);
    border-radius: 50%;
    box-shadow: inset 48px 48px white;
  }
  
  .card_title {
    color: #131313;
    line-height: 15px;
  }
  
  .card_subtitle {
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
    text-wrap: wrap;
    font-family: 'Rubik', sans-serif;
  }
  

  .card_description {
    font-size: 14px;
    opacity: 0;
    transition: opacity .5s;
  }

  .card:hover .card_content {
    transform: translateY(0);
  }
  
  .card:hover .card_description {
    opacity: 1;
    transition-delay: .25s;
  }

  .ticket-button {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer; 
    text-wrap:balance;
    text-align: center;
}

  .ticket-button {
    --border-right: 6px;
    --text-stroke-color: rgba(200, 200, 200, 0.7);
    --animation-color: #000000;
    --fs-size: 2em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
  }

  .hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
  }

  .ticket-button:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color))
  }

  @media (max-width: 1110px) {
    .card {
      width: 75vw;
      height: 75vw;
    }
  }