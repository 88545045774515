/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.patrocinadors-containerA {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 150px;
  padding-right: 150px;
}

.patrocinadorA {
  position: relative;
  cursor: pointer;
  flex-basis: 30%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #f1f1f1;
  margin: 5px;
  height: 25vw;
  display: flex;
  align-items: center;
}

.patrocinadorA img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.patrocinadorA .infoA {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(29, 29, 29, 0.8);
  color: white;
  padding: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align items vertically with space between them */
  height: 100%; /* Fill the available height within the .patrocinador container */
  font-size: 14px;
}

.patrocinadorA:hover .infoA {
  opacity: 1;
  cursor:default;
}

.patrocinadorA .infoA .button-containerA {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.patrocinadorA .infoA button {
  background-color: #D91E01;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  font-size: 1.5vw;
}

.patrocinadorA .infoA button:hover {
  background-color: #941400;
}

.patrocinadorA .infoA .descriptionA {
  max-height: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

p {
  font-size: 1vw;
}

/* Responsive styles */
@media screen and (max-width: 1110px) {
  .patrocinadorA {
    flex-basis: 75%;
    border: none;
    margin: 0;
    justify-content: center;
  }
  .patrocinadors-containerA {
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .patrocinadorA img {
    width: auto;
    height: 100%;
    max-height: 100%;
    height: auto;
    display: block;
  }
  p {
    font-size: 3vw !important;
  }

  .patrocinadorA:hover .infoA {
    font-size: 1vw;
  }
}