/*

Joel Cano i Puigvert
Last update: 28/07/2023
https://github.com/llop10101

*/

.circle-valors {
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: #DADADA;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 2s;
  margin: 20px;
  overflow: hidden;
}

.circle-valors:hover {
  color: #F1F1F1;
  background-color: #414141;
  transform: scale(1.05);
  transition: transform 1s;
}

.circle-valors .text {
  justify-content: justify;
  font-family: 'Rubik', sans-serif;
  font-size: 34px;
  z-index: 1;
  margin: 50px;
  cursor: default;
  font-weight: bold; /* Text is bold when not hovered */
}

.circle-valors:hover .text {
  font-weight: normal; /* Text is regular when hovered */
  font-size: 1.25vw;
}

@media (max-width: 1110px) {
  .circle-valors {
    width: 50vw;
    height: 50vw;
  }
  .circle-valors .text {
    font-size: 24px;
  }
  .circle-valors:hover .text {
    font-weight: normal; /* Text is regular when hovered */
    font-size: 8px;
  }
  .circle-valors:hover {
    transform: scale(1.2);
  }
}
