.gif-container {
  position: relative;
  width: 100%; /* Amplada de la pantalla */
  height: 100%; 
  overflow: hidden; /* Assegura que el GIF no es desbordarà */
}

.gif {
  width: 320px;
  height: 100%;
  animation: moveAndPause 10.01s ease infinite;
}

@keyframes moveAndPause {
  0% {
    transform: translateX(0);
  } 
  45% {
    transform: translateX(20vw);
  }
  60% {
    transform: translateX(20vw);
  }
  65% {
    transform: translateX(40vw);
  }
  70% {
    transform: translateX(40vw);
  }
  75% {
    transform: translateX(60vw);
  }
  80% {
    transform: translateX(60vw);
  }
  85% {
    transform: translateX(80vw);
  }
  93% {
    transform: translateX(80vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

@media (max-width: 1110px) {
  .gif {
    width: 160px;
  }
}