/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.colored-box {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1D1D1B;
  padding: 20px;
}

.colored-box p {
  margin-top: 10px;
  font-size: 30px;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

.colored-box img {
  max-width: 250px;
  height: auto;
}

.colored-box-link {
  display: flex;
  text-decoration: none;
}

.box-image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.centered-colored-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.centered-colored-box img {
  width: 200px;
  height: auto;
}

.centered-colored-box p {
  font-size: 24px;
  max-width: 500px;
  word-wrap: break-word;
  font-family: 'Rubik', sans-serif;
}

.circle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1D1D1B;
}

.red-circle {
  width: 350px;
  height: 100%;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
}

.red-circle:hover {
  transform: scale(1.1);
}

.red-circle a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}


@media (max-width: 1110px) {
  .box-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .colored-box p{
    font-size: 16px;
  }

  .centered-colored-box p {
    font-size: 5vw !important;
  }
}