/* Calendari.css */
.calendari-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    border-bottom: 20px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .background-image,
  .overlay-image {
    
    width: 500px;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay-image {
    left: 25%;
    width: 70%;
    height: auto;
    /* Afegeix qualsevol altre estil que vulguis per a la imatge superposada */
    opacity: 1;
  }
  
  @media (max-width: 421px) {
    .overlay-image {
        left: -95%;
        top: 3%;
        width: 200%;
        height: auto;
    }
  }