/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.fixed-image {
    position: fixed;
    bottom: 300px;
    right: 200px;
    width: 300px;
    opacity: 0; /* Comença amb opacitat 0 per a l'efecte d'aparició */
    animation: bounceIn 1s forwards; /* Nom de l'animació i duració */
    background-color: transparent;
  }
  
  @keyframes bounceIn {
    from {
      opacity: 0;
      transform: translateY(100px); /* Desplaçament inicial cap avall */
    }
    25% {
      transform: translateY(-30px); /* Anima a dalt una mica */
    }
    50% {
      transform: translateY(0); /* Anima cap a dalt completament */
    }
    75% {
      transform: translateY(-15px); /* Anima a dalt una mica per rebotar */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* Torna a la posició original */
    }
  }
  

  @media (max-width: 1110px) {
    .fixed-image {
      width: 100px;
      right : 0px;
      bottom: 150px;
      background-color: rgba(255,255,255,0.5);
    }
  }