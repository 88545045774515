/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.marquee-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .marquee-container img {
    max-width: 100px;
  }
  