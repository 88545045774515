/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.burger-menu {
    position: relative;
    font-family: 'Rubik', sans-serif;
  }
  
  .burger-icon {
    display: block;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .burger-icon span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #333;
    margin: 6px 0;
    transition: transform 0.3s;
  }
  
  .menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #D91E01;
    ;
    padding: 10px;
    display: block;
    z-index: 3;
    overflow-y: auto;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu ul li {
    padding: 7.5px 10px;
    cursor: default;
  }

  .menu a {
    text-decoration: none;
    color: #F1F1F1;
  }

  
  @media (min-width: 1110px) {
    .burger-icon {
        display: none;
    }
    .burger-menu {
      display: none;
    }
  }
  