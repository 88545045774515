/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.footer {
    background-color: #FFFFFF;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer .social-icons {
    display: flex;
    align-items: center;
  }
  
  .footer .social-icons i {
    margin-right: 10px;
    font-size: 24px;
  }
  
  .footer .contact-info {
    display: flex;
    align-items: center;
  }
  
  .footer .contact-info p {
    margin-left: 10px;
    font-family: 'Rubik', sans-serif;
  }
  
  .icon {
    color: #DADADA;
    width: 25px;
    height: 25px;
    padding: 5px;
  }

  .icon-instagram:hover svg {
      color:#fff;
    background-image: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
  }
  .icon-facebook:hover svg {
    color: #3b5998;
  }
  
  .icon-twitter:hover svg {
    color: #00acee;
  }
  
  .icon-tiktok:hover svg {
    color: black;
  }
  
  .email-link{
    color:#fff;
    text-decoration: none;
  }
  
  .footer-contact p {
    color: #fff;
    font-family: 'Rubik', sans-serif;
  }

  .footer-contact a {
    font-family: 'Rubik', sans-serif;
    color : #878787
  }

  .footer-contact:hover a {
    color: #1D1D1D;
  }