/*

Joel Cano i Puigvert
Last update: 28/07/2023
https://github.com/llop10101

*/

.valors-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    grid-gap: 0px;
  }
  
  .valors {
    position: relative;
  }
  
  .valors-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .valors-content p {
    margin-top: 47%;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }


  /* Estils per pantalla més petita que 1110px (1 columna) */
@media (max-width: 1110px) {
  .valors-layout {
    grid-template-columns: 1fr;
  }

  .valors-content p {
    margin-top: 0; /* Eliminem el marge superior per centrar el contingut verticalment */
  }
}