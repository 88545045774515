/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.column-layout {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  display: flex;
}

.column-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-betweens;
}

@media (max-width: 1110px) {
  .column-layout {
    flex-direction: column; 
  }

  .column {
    width: 100%; 
  }
}
