.container {
    background-color: #FFF;
    color: #000;
    padding: 20px;
    font-family: 'Rubik', sans-serif;
    text-align: left;
    margin-left: 5vw;
    margin-right: 5vw;

  }

  .text i {
    font-style: italic; 
  }
  
  .text b {
    font-weight: bold;
  }

  .text p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;    
  }
  
  h4 {
    text-align: center;
  }

  .lletra-petita {
    color: grey;
  }

  .lletra-petita p {
    font-size: 18px;
  }


  @media (max-width: 1110px) {
    h5 {
      font-size: 4vw;
    }

    h4 {
     font-size: 4vw; 
    }
  
  }