.flex-container {
    display: flex;
    flex-direction: column; 
    background-color: white;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; 
  }
  
  .logo {
    width: 20%; 
    height: auto; 
  }
  
  .text-obra {
    max-width: 600px;
    font-size: 18px;
    color: black;
    text-align: justify;
    margin: 0px auto;
    line-height: 1.6;
    font-family: 'Rubik', sans-serif;
  }
  
  @media (max-width:1110px) {
    .text-obra {
        margin-left: 10px;
        margin-right: 10px;
      }
  }