/* Estils CSS per al component de patrocinadors */
/* Llop10101 - Actualitzat el 27/07/2023 */

/* Contenidor de patrocinadors */
.patrocinadors-containerC {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 150px;
  padding-right: 150px;
}

/* Estils per a cada patrocinador individual */
.patrocinadorC {
  position: relative;
  cursor: pointer;
  flex-basis: 18%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #f1f1f1;
  margin: 5px;
  height: 15vw;
  /* Centra la imatge verticalment al mig del patrocinador */
  display: flex;
  align-items: center;
}

/* Estils per a la imatge del patrocinador */
.patrocinadorC img {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  /* Alinea la imatge al centre en quant a l'alçada */
  margin: auto;
}

/* Estils per a la informació del patrocinador */
.patrocinadorC .infoC {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(29, 29, 29, 0.8);
  color: white;
  padding: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Alinya els elements verticalment amb espai entre ells */
  height: 100%; /* Omple l'alçada disponible dins del contenidor .patrocinador */
  font-size: 14px;
}

/* Mostra la informació del patrocinador al fer hover */
.patrocinadorC:hover .infoC {
  height: 100%;
  opacity: 1;
  cursor: default;
}

/* Estils per al contenidor de botons */
.patrocinadorC .infoC .button-containerC {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

/* Estils per als botons */
.patrocinadorC .infoC button {
  background-color: #D91E01;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  font-size: 1.5vw;
}

/* Efecte al fer hover sobre els botons */
.patrocinadorC .infoC button:hover {
  background-color: #941400;
}

/* Estils per a la descripció del patrocinador */
.patrocinadorC .infoC .descriptionC {
  max-height: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Estils per als paràgrafs de text */
p {
  font-size: 1vw;
}

/* Estils responsius */
@media screen and (max-width: 1110px) {
  .patrocinadorC {
    flex-basis: 33%;
    border: none;
    margin: 0px;
  }
  .patrocinadorC .infoC {
    font-size: 16px;
  }
  .patrocinadors-containerC {
    padding-left: 0px;
    padding-right: 0px;
  }
}
