.vertical-timeline::before {
      background: black !important;
  }

  .custom-link-button {
    position: relative;
    display: block;
    cursor: pointer;
    outline: none;
    border: 0;
    margin: 0 auto;    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    width: 12rem;
    height: auto;
    text-align: center;
    align-items: center;
  }
  
  .custom-link-button .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
  }
  
  .custom-link-button .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }
  
  .custom-link-button .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }
  
  .custom-link-button .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.05rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
  }
  
  .custom-link-button .button-text {
    font-family: 'Roboto', sans-serif;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }
  
  .custom-link-button:hover .circle {
    width: 100%;
  }
  
  .custom-link-button:hover .circle .icon.arrow {
    background: transparent;
    transform: translate(1rem, 0);
  }
  
  .custom-link-button:hover .button-text {
    color: #fff;
  }
  
.rubik-title {
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.rubik-subtitle {
  font-family: 'Rubik', sans-serif;
}

.rubik-text {
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

.roboto-title {
  font-family: 'Roboto', sans-serif;
}

.rubik-date {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 14px;
}
