/*

Joel Cano i Puigvert
Last update: 29/11/2023
https://github.com/llop10101

*/

.marquee-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

.marquee-container img {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin: 5px;
}

p {
  font-family: 'Rubik', sans-serif;
}
