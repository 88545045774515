/*

Joel Cano i Puigvert
Last update: 27/07/2023
https://github.com/llop10101

*/

.patrocinadors-containerAG {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 150px;
    padding-right: 150px;
  }
  
  .patrocinadorAG {
    position: relative;
    cursor: pointer;
    flex-basis: 18%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #f1f1f1;
    margin: 5px;
  }
  
  .patrocinadorAG img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .patrocinadorAG .infoAG {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(29, 29, 29, 0.8);
    color: white;
    padding: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align items vertically with space between them */
    height: 100%; /* Fill the available height within the .patrocinador container */
    font-size: 14px;
  }
  
  .patrocinadorAG:hover .infoAG {
    height: 100%;
    opacity: 1;
    cursor:default;
  }
  
  .patrocinadorAG .infoAG .button-containerAG {
    margin-top: 8px;
    display: flex;
    justify-content: center;
  }
  
  .patrocinadorAG .infoAG button {
    background-color: #D91E01;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    font-size: 1.3vw;
  }
  
  .patrocinadorAG .infoAG button:hover {
    background-color: #941400;
  }
  
  .patrocinadorAG .infoAG .descriptionAG {
    max-height: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    font-size: 1vw;
  }

  /* Responsive styles */
@media screen and (max-width: 1110px) {
    .patrocinadorAG {
      flex-basis: 33%;
      border: none;
      margin: 0px;
    }
    .patrocinadorAG .infoAG {
        font-size: 16px;
    }
    .patrocinadors-containerAG {
        padding-left:0px;
        padding-right:0px;
      }
}